import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Text, useTheme } from 'react-native-paper';
import { TouchableOpacity } from 'react-native-gesture-handler';
import Icon from '@expo/vector-icons/MaterialCommunityIcons';
import { HoverCard } from '~components/hoverCard';
import { DateToString } from '~utils/dateHelper';
import { format, isFuture } from 'date-fns';

export const CalendarItem = ({ appointment }) => {
	const theme = useTheme();
	
	const appointmentDate = new Date(appointment.StartDate);
	const formatDate = DateToString(appointmentDate);
	
	let formatTime = 'All Day';
	// Convert the time string into a JavaScript Date object
	if (appointment.StartTime) {
		const timeParts = appointment.StartTime.split(':');
		const dateObject = new Date();
		dateObject.setHours(Number(timeParts[0]), Number(timeParts[1]), Number(timeParts[2]));
	
		// Format the Date object
		formatTime = format(dateObject, 'h:mm a');
	}
	
	let title = isFuture(new Date(appointment.StartDate)) ? 'Upcoming Appointment' : 'Past Appointment';


	const styles = StyleSheet.create({
		appointmentView : {
			margin: 15,
			color: theme.colors.primary
		},
		title: {
			color: theme.colors.primary,
			fontSize: 18,
			fontFamily: 'proxima-nova-bold'
		},
		subject: {
			fontSize: 16,
			fontFamily: 'proxima-nova-bold',
			marginTop: 5,
			marginBottom: 10
		},
		dateTime: {
			flex: 1,
			flexDirection: 'row',
			marginBottom: 10
		},
		dateTimeText: {
			marginLeft: 10,
			fontSize: 18,
			alignSelf: 'center'
		},
		date: {
			flex: 0.5,
			flexDirection: 'row'
		},
		time: {
			flex: 0.5,
			flexDirection: 'row'
		},
		details: {
			fontSize: 16
		},
		detailsView: {
			flexDirection: 'row'
		}
	});

	const handlePress = () => {
		// null handler
	};

	return (
		<>
			<TouchableOpacity
				disabled
				onPress={() => handlePress()}
			>
				<HoverCard>
					<View style={styles.appointmentView}>
						<Text style={styles.title}>{title}</Text>
						<Text style={styles.subject}>{appointment.Subject}</Text>
						<View style={styles.dateTime}>
							<View style={styles.date}>
								<Icon name={'calendar-month-outline'} size={25} color={theme.colors.primary} />
								<Text style={styles.dateTimeText}>{formatDate}</Text>
							</View>
							<View style={styles.time}>
								<Icon name={'clock-time-eight-outline'} size={25} color={theme.colors.primary} />
								<Text style={styles.dateTimeText}>{formatTime}</Text>
							</View>
						</View>
						<View>
							<View style={styles.detailsView}>
								<Text style={styles.details}>Location: </Text>
								<Text style={styles.details}>{appointment.Location}</Text>
							</View>
							<View style={styles.detailsView}>
								<Text style={styles.details}>Attendance: </Text>
								<Text style={styles.attendance}>{appointment.CPDAttendanceRequired}</Text>
							</View>
							
							<View style={styles.detailsView}>
								<Text style={styles.details}>Notes: </Text>
								<Text style={styles.attendance}>{appointment.CPDNotes}</Text>								
							</View>

						</View>
					</View>
				</HoverCard>
			</TouchableOpacity>
		</>		
	);
};
