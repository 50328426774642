import React, { useContext, useEffect, useState } from 'react';
import {
	ImageBackground,
	Platform,
	ScrollView,
	StyleSheet,
	TouchableOpacity,
	View
} from 'react-native';
import { Badge, Button, Card, Text, Title, useTheme } from 'react-native-paper';
import update from 'immutability-helper';
import call from 'react-native-phone-call';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';

import { CaseContext } from '~context/caseContext';
import { AppContext } from '~context/appContext';
import { LoginRedirect } from '~utils/loginRedirect';

import { V1Request } from '~utils/apiRequest';
import { useLocalData } from '~utils/localStorage';

import bg_dark from '@@images/0_shared/generic_bg_dark.jpg';
import bg_light from '@@images/0_shared/generic_bg_light.jpg';
import { CaseProgress } from '~components/caseProgress';
import { WebContainer } from '~components/webContainer';
import BrandBackground from '~components/brandBackground';
import { HoverCard } from '~components/hoverCard';


// eslint-disable-next-line no-unused-vars
export const Cases = ({ route, navigation }) => {
	const app = useContext(AppContext);
	const _case = useContext(CaseContext);
	const theme = useTheme();

	const [bg, setBg] = useState(
		theme.dark ? bg_dark : bg_light
	);
	const settingsData = useLocalData('settingsData', {});
	const [cases, setCases] = useState([]);
	const [caseProgress, setCaseProgress] = useState([]);
	
	useEffect(() => {
		setBg(theme.dark ? bg_dark : bg_light);
	}, [theme]);

	useEffect(() => {
		const loadData = async () => {
			const response = await V1Request(
				'get',
				'GetCases',
				null,
				null,
				'json',
				app.setLoading
			);

			const response2 = await V1Request(
				'get',
				'GetUnreadMsgCount',
				null,
				null,
				'json',
				app.setLoading
			);

			if (response.status === 401 || response.status === 403) {
				LoginRedirect(app, navigation);
				return;
			} else {
				response.data.forEach((x) => (x.MessageCount = response2.data[x.LawsuitId]));
				setCases(response.data);
			}
		};
		loadData();
	}, []);

	useEffect(() => {
		const loadData = async (id) => {
			const response = await V1Request(
				'get',
				'GetCaseProgress',
				null,
				`?lawsuitId=${id}`,
				'json',
				null
			);

			if (response.status === 401 || response.status === 403) {
				LoginRedirect(app, navigation);
				return;
			}

			setCaseProgress(prog => update(prog, { $push: [response.data] }));
		};

		cases.map((thisCase) => {
			loadData(thisCase.LawsuitId);
		});

	}, [cases]);

	const messages_icon = (count) => {
		if (count) {
			return (
				<>
					<Icon
						style={{ top: 10, right: 10, position: 'absolute' }}
						name="android-messages"
						size={35}
						color={theme.colors.primary}
						onPress={() => {
							alert('Settings Icon Clicked');
						}}
					/>
					<Badge
						icon="camera"
						visible={true}
						size={25}
						style={{ top: 0, position: 'absolute' }}
					>
						{count}
					</Badge>
				</>
			);
		}
	};

	const callOptions = {
		number: settingsData?.FirmContactPhoneNumber,
		prompt: true,
		skipCanOpen: true
	};

	const styles = StyleSheet.create({
		backgroundImage: {
			flex: 1,
			justifyContent: 'center'
		},
		contentView: {
			flex: 1,
			justifyContent: 'flex-start',
			padding: 25
		},
		cardTextKey: {
			fontSize: 16,
			letterSpacing: -0.35,
			color: theme.colors.placeholder,
			marginLeft: 15,
			flex: 1
		},
		cardTextValue: {
			fontSize: 16,
			letterSpacing: -0.35,
			color: theme.colors.placeholder,
			marginLeft: 15,
			flex: 3
		},
		cardTextKeyMobile: {
			fontSize: 16,
			letterSpacing: -0.35,
			color: theme.colors.placeholder,
			marginLeft: 15,
			flex: 5
		},
		cardTextValueMobile: {
			fontSize: 16,
			letterSpacing: -0.35,
			color: theme.colors.placeholder,
			marginLeft: 15,
			flex: 6
		},
		cardTextContainer: {
			flex: 1,
			flexDirection: 'row'
		},
		titleText: {
			marginBottom: 15
		},
		detailsView: {
			marginTop: -5
		},
		contentViewWeb: {
			flex: 1,
			justifyContent: 'flex-start'
		},
		titleTextWeb: {
			marginBottom: 30
		},
		backgroundImage: {
			height: '100%'
		}
	});

	return <>{Platform.OS === 'web' ? WebView() : MobileView()}</>;

	function WebView() {
		return (
			<BrandBackground>
				<ScrollView>
					<WebContainer size={'md'}>
						<View style={styles.contentViewWeb}>
							<Title style={styles.titleTextWeb}>
								Select a case to continue
							</Title>
							{cases?.constructor === Array
								? cases.map((cases) => {
									return (
										<TouchableOpacity
											key={cases.LawsuitId}
											onPress={() => {												
												// Set Case Context values, since tab view screens are
												// defined outside of the screen component and therefore
												// do not have access to props or navigation
												// This is to avoid rendering all tabs on every tab change
												_case.setLawsuitId(cases.LawsuitId);
												_case.setCaseName(cases.Name);
												_case.setClient(cases.Client);
												_case.setCaseProgress(caseProgress.find(prog => {
													return prog.LawsuitId === cases.LawsuitId;
												}));
												navigation.replace('Case', {
													lawsuitId: cases.LawsuitId,
													client: cases.Client,
													name: cases.Name,
													caseProgress: caseProgress.find(prog => {
														return prog.LawsuitId === cases.LawsuitId;
													})
												});
											}}
										>
											<HoverCard>
												<Card.Title title={cases.Client} />
												<View style={styles.detailsView}>
													<View style={styles.cardTextContainer}>
														<Text style={styles.cardTextKey}>
															Case:
														</Text>
														<Text style={styles.cardTextValue}>
															{cases.Name}
														</Text>
													</View>
													<View style={styles.cardTextContainer}>
														<Text style={styles.cardTextKey}>
															Case Type:
														</Text>
														<Text style={styles.cardTextValue}>
															{cases.LawsuitTypeName}
														</Text>
													</View>
													{cases.MasterCasePhase && (
														<View style={styles.cardTextContainer}>
															<Text style={styles.cardTextKey}>
																Global Case Status:
															</Text>
															<Text style={styles.cardTextValue}>
																{cases.MasterCasePhase}
																{cases.MasterCaseSubPhase &&
																	` | ${cases.MasterCaseSubPhase}`}
															</Text>
														</View>
													)}
													<View style={{ padding: 5, marginBottom: 0 }}>
														<CaseProgress caseProgress={
															caseProgress.find(prog => {
																return prog.LawsuitId === cases.LawsuitId;
															})
														} />
													</View>
												</View>
												{messages_icon(cases.MessageCount)}
											</HoverCard>
										</TouchableOpacity>
									);
								})
								: null}
						</View>
					</WebContainer>
				</ScrollView>
			</BrandBackground>
		);
	}

	function MobileView() {
		return (
			<ImageBackground
				resizeMode="cover"
				style={styles.backgroundImage}
				source={bg}
			>
				<ScrollView>
					<View style={styles.contentView}>
						<Title style={styles.titleText}>Select a case to continue</Title>
						{cases?.constructor === Array
							? cases.map((cases) => {
								return (
									<TouchableOpacity
										key={cases.LawsuitId}
										onPress={() => {
										// Set Case Context values, since tab view screens are
										// defined outside of the screen component and therefore
										// do not have access to props or navigation
										// This is to avoid rendering all tabs on every tab change
											_case.setLawsuitId(cases.LawsuitId);
											_case.setCaseName(cases.Name);
											_case.setClient(cases.Client);
											_case.setCaseProgress(caseProgress.find(prog => {
												return prog.LawsuitId === cases.LawsuitId;
											}));
											navigation.replace('Case', {
												lawsuitId: cases.LawsuitId,
												client: cases.Client,
												name: cases.Name,
												caseProgress: caseProgress.find(prog => {
													return prog.LawsuitId === cases.LawsuitId;
												})
											});
										}}
									>
										<HoverCard>
											<Card.Title title={cases.Client} />
											<View style={styles.detailsView}>
												<View style={styles.cardTextContainer}>
													<Text style={styles.cardTextKeyMobile}>
													Case:
													</Text>
													<Text style={styles.cardTextValueMobile}>
														{cases.Name}
													</Text>
												</View>
												<View style={styles.cardTextContainer}>
													<Text style={styles.cardTextKeyMobile}>
													Case Type:
													</Text>
													<Text style={styles.cardTextValueMobile}>
														{cases.LawsuitTypeName}
													</Text>
												</View>
												{cases.MasterCasePhase && (
													<View style={styles.cardTextContainer}>
														<Text style={styles.cardTextKeyMobile}>
														Global Case Status:
														</Text>
														<Text style={styles.cardTextValueMobile}>
															{cases.MasterCasePhase}
															{cases.MasterCaseSubPhase &&
															` | ${cases.MasterCaseSubPhase}`}
														</Text>
													</View>
												)}
												<View style={{ padding: 5, marginBottom: 0 }}>
													<CaseProgress caseProgress={
														caseProgress.find(prog => {
															return prog.LawsuitId === cases.LawsuitId;
														})
													} />
												</View>

											</View>
											{messages_icon(cases.MessageCount)}
										</HoverCard>
									</TouchableOpacity>
								);
							})
							: null}
						<Button
							mode="text"
							onPress={() => call(callOptions).catch(console.error)}
							uppercase={false}
						>
						Need help?
						</Button>
					</View>
				</ScrollView>
			</ImageBackground>
		);
	}
};
