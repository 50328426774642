import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import {
	Platform,
	StyleSheet,
	View,
	ScrollView
} from 'react-native';
import { useNavigation, useRoute } from '@react-navigation/native';
import { HeaderBackButton } from '@react-navigation/elements';
import { Button, Divider, Title } from 'react-native-paper';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

import { V1Request } from '~utils/apiRequest';
import { LoginRedirect } from '~utils/loginRedirect';
import { AppContext } from '~context/appContext';
import BrandBackground from '~components/brandBackground';
import { WebContainer } from '~components/webContainer';

import { DatePickerBasic } from '~components/formInputs/datePicker';
import { TextAreaBasic } from '~components/formInputs/textArea';
import { TextFieldBasic } from '~components/formInputs/textField';
import { DropDownBasic } from '~components/formInputs/dropDown';

export const TreatmentForm=() => {
	const app = useContext(AppContext);
	const navigation = useNavigation();
	const route = useRoute();
	
	const [existingMp, setExistingMp] = useState({});
	const [mpName, setMpName] = useState('');
	const [treatmentDate, setTreatmentDate] = useState();
	const [treatmentNote, setTreatmentNote] = useState('');
	
	// const [showAddress, setShowAddress] = useState(false);
	const [addressLine1, setAddressLine1] = useState('');
	const [addressLine2, setAddressLine2] = useState('');
	const [addressCity, setAddressCity] = useState('');
	const [addressState, setAddressState] = useState('');
	const [addressZipCode, setAddressZipCode] = useState('');
	const [submitDisabled, setSubmitDisabled] = useState(true);
	
	const { mps } = route.params;
		
	const styles = StyleSheet.create({
		contentView: {
			flex: 1,
			justifyContent: 'flex-start',
			padding: 25,			
		},
		submitButton: {
			justifyContent: 'center',
			height: 55,
			marginTop: 25,
			marginBottom: 25,
		},
		submitButtonText: {
			fontSize: 18,
			padding: 5
		},
		titleTextWeb: {
			textAlign: 'center'
		},
		divider: {
			marginTop: 15
		},
		formTopBar: {
			flexDirection: 'row',
			justifyContent: 'space-between',
		}
	});

	const handleSubmit = async () => {
	
		const mpTreatment = {
			MedicalProviderId: existingMp.Id,
			MedicalProviderName: mpName,
			TreatmentDate: treatmentDate,
			TreatmentNote: treatmentNote,
			AddressLine1: addressLine1,
			AddressLine2: addressLine2,
			City: addressCity,
			State: addressState,
			ZipCode: addressZipCode
		};

		mpTreatment.AddressLine1 = addressLine1;
		mpTreatment.AddressLine2 = addressLine2;
		mpTreatment.City = addressCity;
		mpTreatment.State = addressState;
		mpTreatment.ZipCode = addressZipCode;
		
		const response = await V1Request(
			'post',
			'CreateMedicalProviderTreatment',
			mpTreatment,
			`?lawsuitId=${route.params.lawsuitId}`,
			'json',
			app.setLoading
		);

		if (response.status === 401 || response.status === 403) {
			LoginRedirect(app, navigation);
			return;
		} else {
			navigation.push('Case', { lawsuitId: route.params.lawsuitId,  tab: 'treatment' });
		}
	};

	useLayoutEffect(() => {
		navigation.setOptions({
			headerLeft: () => backButton,
			headerTitle: 'Add a Treatment Note'
		});
	}, [navigation]);

	useEffect(() => {
		if (existingMp.Id > 0) {
			setMpName(existingMp.Name);
		} else setMpName('');
	},[existingMp]);

	useEffect(() => {
		if (treatmentDate != '' && treatmentNote != '' && mpName != '') {
			setSubmitDisabled(false);
		} else setSubmitDisabled(true);
	},[treatmentDate, treatmentNote]);

	const backButton = <HeaderBackButton
		onPress={() => {
			navigation.pop();
		}}
	/>;

	return <>{Platform.OS === 'web' ? WebView() : MobileView()}</>;

	function Questions() {
		return (
			<View style={styles.contentView}>
				<DropDownBasic key='existingMp' question='Select Medical Provider' answer={existingMp} setAnswer={setExistingMp} values={mps}  />
				{ existingMp.Id === null &&
					<>
						<TextFieldBasic key='mpname' question='What is the name of the medical provider who provided treatment?' answer={mpName} setAnswer={setMpName} />
						<TextFieldBasic key='address1' question='Address Line 1' answer={addressLine1} setAnswer={setAddressLine1} />
						<TextFieldBasic key='address2' question='Address Line 2' answer={addressLine2} setAnswer={setAddressLine2} />
						<TextFieldBasic key='addressCity' question='City' answer={addressCity} setAnswer={setAddressCity} />
						<TextFieldBasic key='addressState' question='State' answer={addressState} setAnswer={setAddressState} />
						<TextFieldBasic key='addressZipCode' question='Zip Code' answer={addressZipCode} setAnswer={setAddressZipCode} />
						
					</>
				}
				{ (existingMp.Id === null || existingMp.Id > 0) &&
					<>
						<DatePickerBasic key='treatmentdate' question='What was the date of the treatment?' answer={treatmentDate} setAnswer={setTreatmentDate} />
						<TextAreaBasic key='treatmentnote' question='Provide a description of the treatment.' answer={treatmentNote} setAnswer={setTreatmentNote} />
					</>
				}
					
				<Button
					mode="contained"
					onPress={handleSubmit}
					style={styles.submitButton}
					labelStyle={styles.submitButtonText}
					uppercase={false}
					disabled={submitDisabled}
				>
						Submit
				</Button>
			</View>
		);
	}
	
	function WebView() {
		return (
			<BrandBackground>
				<ScrollView>
					<WebContainer size="md">
						<View style={styles.formTopBar}>
							{backButton}
							<Title style={styles.titleTextWeb}>
								Add a Treatment Note
							</Title>
							<Divider style={styles.divider} />
						</View>
						{Questions()}
					</WebContainer>
				</ScrollView>
			</BrandBackground>
		);
	}

	function MobileView() {
		return (
			<KeyboardAwareScrollView
				extraHeight={Platform.OS === 'android' ? 25 : 0}
				enableOnAndroid
				enableResetScrollToCoords={false}
			>
				{Questions()}
			</KeyboardAwareScrollView>
		);
	}
};
